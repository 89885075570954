import * as React from "react";

function SvgComponent(props) {
  return (

    <svg height="64px" 
    width="64px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve" fill="#000000">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier"> 
      <path d="M486.4,115.2H364.117C341.333,79.36,301.483,55.467,256,55.467S170.667,79.36,147.883,115.2H25.6 C11.52,115.2,0,126.72,0,140.8v290.133c0,14.08,11.52,25.6,25.6,25.6h460.8c14.08,0,25.6-11.52,25.6-25.6V140.8 C512,126.72,500.48,115.2,486.4,115.2z M256,72.533c61.184,0,110.933,49.749,110.933,110.933S317.184,294.4,256,294.4 s-110.933-49.749-110.933-110.933S194.816,72.533,256,72.533z M17.067,140.8c0-4.693,3.84-8.533,8.533-8.533h113.152 c-3.584,8.107-6.315,16.725-8.192,25.6H17.067V140.8z M17.067,174.933h111.36c-0.171,2.816-0.427,5.632-0.427,8.533 c0,15.019,2.731,29.269,7.509,42.667H17.067V174.933z M494.933,430.933c0,4.693-3.84,8.533-8.533,8.533H25.6 c-4.693,0-8.533-3.84-8.533-8.533V243.2h125.781c21.504,40.533,64.171,68.267,113.152,68.267s91.648-27.733,113.152-68.267h125.781 V430.933z M494.933,226.133H376.491c4.779-13.397,7.509-27.648,7.509-42.667c0-2.901-0.256-5.717-0.427-8.533h111.36V226.133z M494.933,157.867H381.44c-1.877-8.875-4.608-17.493-8.192-25.6H486.4c4.693,0,8.533,3.84,8.533,8.533V157.867z"></path> 
      <path style={{fill:"#FFFFFF"}} d="M256,72.533c-61.184,0-110.933,49.749-110.933,110.933S194.816,294.4,256,294.4 s110.933-49.749,110.933-110.933S317.184,72.533,256,72.533z M236.459,257.792c-1.707,1.621-3.84,2.475-6.059,2.475 c-2.219,0-4.352-0.853-6.059-2.475l-51.2-51.2c-3.328-3.328-3.328-8.789,0-12.117l25.6-25.6c3.328-3.328,8.789-3.328,12.117,0 l19.541,19.627l70.741-70.827c3.328-3.328,8.789-3.328,12.117,0l25.6,25.6c3.328,3.328,3.328,8.789,0,12.117L236.459,257.792z"></path> 
      <path d="M426.667,328.533c-11.273,0-21.982,4.506-29.867,12.211c-7.885-7.706-18.594-12.211-29.867-12.211 c-23.526,0-42.667,19.14-42.667,42.667s19.14,42.667,42.667,42.667c11.273,0,21.982-4.506,29.867-12.211 c7.885,7.706,18.594,12.211,29.867,12.211c23.526,0,42.667-19.14,42.667-42.667S450.193,328.533,426.667,328.533z M426.667,396.8 c-9.114,0-17.613-4.907-22.187-12.809c-1.527-2.645-4.924-4.258-7.979-4.258c-0.043,0-0.085,0-0.119,0 c-3.012,0.043-5.769,1.664-7.27,4.267c-4.565,7.893-13.065,12.8-22.178,12.8c-14.114,0-25.6-11.486-25.6-25.6s11.486-25.6,25.6-25.6 c9.105,0,17.604,4.907,22.178,12.8c1.604,2.773,4.386,4.241,7.509,4.258c3.14,0.213,6.212-1.365,7.868-4.25 c4.565-7.893,13.065-12.8,22.187-12.8c14.114,0,25.6,11.486,25.6,25.6C452.275,385.323,440.781,396.8,426.667,396.8z"></path> 
      <path d="M281.6,345.6h-85.333c-4.719,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.814,8.533,8.533,8.533H281.6 c4.719,0,8.533-3.823,8.533-8.533v-34.133C290.133,349.423,286.319,345.6,281.6,345.6z M273.067,379.733H204.8v-17.067h68.267 V379.733z"></path> 
      <path d="M145.067,345.6H59.733c-4.719,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.814,8.533,8.533,8.533h85.333 c4.719,0,8.533-3.823,8.533-8.533v-34.133C153.6,349.423,149.786,345.6,145.067,345.6z M136.533,379.733H68.267v-17.067h68.267 V379.733z"></path> 
      <path d="M338.859,143.275l-25.6-25.6c-3.328-3.328-8.789-3.328-12.117,0L230.4,188.501l-19.541-19.627 c-3.328-3.328-8.789-3.328-12.117,0l-25.6,25.6c-3.328,3.328-3.328,8.789,0,12.117l51.2,51.2c1.707,1.621,3.84,2.475,6.059,2.475 c2.219,0,4.352-0.853,6.059-2.475l102.4-102.4C342.187,152.064,342.187,146.603,338.859,143.275z M230.4,239.701l-39.168-39.168 l13.568-13.568l19.541,19.627c3.328,3.328,8.789,3.328,12.117,0l70.741-70.827l13.568,13.568L230.4,239.701z"></path> 
      <path style={{fill:"#4CAF50"}} d="M320.768,149.333L230.4,239.701l-39.168-39.168l13.568-13.568l19.541,19.627 c3.328,3.328,8.789,3.328,12.117,0l70.741-70.827L320.768,149.333z"></path> 
      <path style={{fill:"#dc8add"}} d="M494.933,140.8v17.067H381.44c-1.877-8.875-4.608-17.493-8.192-25.6H486.4 C491.093,132.267,494.933,136.107,494.933,140.8z"></path> 
      <path style={{fill:"#717272"}} d="M494.933,174.933v51.2H376.491c4.779-13.397,7.509-27.648,7.509-42.667 c0-2.901-0.256-5.717-0.427-8.533H494.933z"></path> 
      <path style={{fill:"#dc8add"}} d="M138.752,132.267c-3.584,8.107-6.315,16.725-8.192,25.6H17.067V140.8c0-4.693,3.84-8.533,8.533-8.533 H138.752z"></path> 
      <path style={{fill:"#717272"}} d="M135.509,226.133H17.067v-51.2h111.36c-0.171,2.816-0.427,5.632-0.427,8.533 C128,198.485,130.731,212.736,135.509,226.133z"></path> 
      <path style={{fill:"#dc8add"}} d="M369.152,243.2c-21.504,40.533-64.171,68.267-113.152,68.267s-91.648-27.733-113.152-68.267H17.067 v187.733c0,4.693,3.84,8.533,8.533,8.533h460.8c4.693,0,8.533-3.84,8.533-8.533V243.2H369.152z M153.6,388.267 c0,4.693-3.84,8.533-8.533,8.533H59.733c-4.693,0-8.533-3.84-8.533-8.533v-34.133c0-4.693,3.84-8.533,8.533-8.533h85.333 c4.693,0,8.533,3.84,8.533,8.533V388.267z M290.133,388.267c0,4.693-3.84,8.533-8.533,8.533h-85.333 c-4.693,0-8.533-3.84-8.533-8.533v-34.133c0-4.693,3.84-8.533,8.533-8.533H281.6c4.693,0,8.533,3.84,8.533,8.533V388.267z M426.667,413.867c-11.264,0-22.016-4.523-29.867-12.203c-7.851,7.68-18.603,12.203-29.867,12.203 c-23.552,0-42.667-19.115-42.667-42.667c0-23.552,19.115-42.667,42.667-42.667c11.264,0,22.016,4.523,29.867,12.203 c7.851-7.68,18.603-12.203,29.867-12.203c23.552,0,42.667,19.115,42.667,42.667C469.333,394.752,450.219,413.867,426.667,413.867z"></path> 
      <path style={{fill:"#FFC107"}} d="M452.267,371.2c0,14.08-11.52,25.6-25.6,25.6c-9.131,0-17.579-4.949-22.187-12.8 c-1.536-2.645-4.949-4.267-8.021-4.267c0,0,0,0-0.085,0c-2.987,0-5.717,1.621-7.253,4.267c-4.608,7.851-13.056,12.8-22.187,12.8 c-14.08,0-25.6-11.52-25.6-25.6s11.52-25.6,25.6-25.6c9.131,0,17.579,4.864,22.187,12.8c1.621,2.731,4.608,4.267,7.509,4.267 c3.072,0.171,6.229-1.365,7.851-4.267c4.608-7.851,13.056-12.8,22.187-12.8C440.747,345.6,452.267,357.12,452.267,371.2z"></path> 
      <g> <rect x="204.8" y="362.667" style={{fill:"#D50000"}} width="68.267" height="17.067"></rect> <rect x="68.267" y="362.667" style={{fill:"#D50000"}} width="68.267" height="17.067"></rect> </g> <g> 
        <path style={{fill:"#9141ac"}} d="M469.333,243.2v162.133c0,4.693-3.84,8.533-8.533,8.533H17.067v17.067 c0,4.693,3.84,8.533,8.533,8.533h460.8c4.693,0,8.533-3.84,8.533-8.533V243.2H469.333z"></path> 
        <path style={{fill:"#9141ac"}} d="M486.4,132.267h-17.067v25.6h25.6V140.8C494.933,136.107,491.093,132.267,486.4,132.267z"></path> </g> 
        <rect x="469.333" y="174.933" style={{fill:"#555656"}} width="25.6" height="51.2"></rect> <path style={{fill:"#F4F4F4"}} d="M296.559,80.316c31.906,19.499,53.308,54.554,53.308,94.618c0,24.218-7.885,46.575-21.103,64.836 c-0.529,0.828-1.135,1.579-1.749,2.347c-20.284,26.53-52.164,43.75-88.081,43.75c-14.327,0-27.972-2.816-40.559-7.782 C215.194,288.367,234.88,294.4,256,294.4c61.184,0,110.933-49.749,110.933-110.933C366.933,136.61,337.707,96.546,296.559,80.316z"></path> </g></svg>
      );
}

export default SvgComponent;
