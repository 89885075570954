import * as React from "react";

function SvgComponent(props) {
  return (
    <svg height="64px" 
    width="64px" viewBox="0 0 128 128" data-name="Слой 1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier"><defs>
        
      <style>
            {`.cls-1{fill:#9141ac;}.cls-2{fill:#424242;}.cls-3{fill:#dc8add;}.cls-4{fill:#e3f2fd;}.cls-5{fill:#ffffff;}.cls-6{fill:#404040;}.cls-7{fill:#99c1f1;}`}
          </style>
        
        
        </defs><title></title><path class="cls-1" d="M48,95,20.31,122.87a10.76,10.76,0,0,1-15.18,0h0a10.76,10.76,0,0,1,0-15.18L33,80"></path><path class="cls-2" d="M12.72,128a12.73,12.73,0,0,1-9-21.72l27.87-27.7a2,2,0,1,1,2.82,2.84L6.54,109.11a8.73,8.73,0,0,0,12.35,12.34L46.58,93.59a2,2,0,0,1,2.84,2.82L21.72,124.28A12.64,12.64,0,0,1,12.72,128Z"></path><circle class="cls-3" cx="76" cy="52" r="50" transform="translate(-14.51 68.97) rotate(-45)"></circle><path class="cls-2" d="M76,104a52,52,0,1,1,36.77-15.21h0A51.84,51.84,0,0,1,76,104ZM76,4a48,48,0,1,0,33.94,14A47.85,47.85,0,0,0,76,4Zm35.36,83.33h0Z"></path><circle class="cls-4" cx="76" cy="52" r="38" transform="translate(-14.51 68.97) rotate(-45)"></circle><path class="cls-2" d="M76,92a40,40,0,1,1,28.28-11.7A39.87,39.87,0,0,1,76,92Zm0-76a36,36,0,1,0,25.46,10.53A35.89,35.89,0,0,0,76,16Z"></path><path class="cls-5" d="M106,52S94.3,70,76,70,46,52,46,52,57.7,34,76,34,106,52,106,52Z"></path><path class="cls-6" d="M76,72C56.84,72,44.83,53.86,44.32,53.09a2,2,0,0,1,0-2.18C44.83,50.14,56.84,32,76,32s31.17,18.14,31.68,18.91a2,2,0,0,1,0,2.18C107.17,53.86,95.16,72,76,72ZM48.46,52C51.28,55.77,61.57,68,76,68s24.71-12.23,27.54-16C100.71,48.23,90.42,36,76,36S51.29,48.23,48.46,52Z"></path><circle class="cls-7" cx="76" cy="52" r="10"></circle><path class="cls-6" d="M76,64A12,12,0,1,1,88,52,12,12,0,0,1,76,64Zm0-20a8,8,0,1,0,8,8A8,8,0,0,0,76,44Z"></path></g></svg>
   );
}

export default SvgComponent;
