import * as React from "react";

function SvgComponent(props) {
  return (
    <svg 
      version="1.1" 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 512 512" 
      xmlSpace="preserve" 
      width="64px" 
      height="64px" 
      fill="#000000">
        
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier"> <g> <polygon style={{fill:"#FF8E00"}} points="271.673,0 256,0 245.551,34.721 256,69.441 271.673,69.441 "></polygon> 
          <polygon style={{fill:"#FF8E00"}} points="271.673,512 271.673,442.559 256,442.559 245.551,477.279 256,512 "></polygon> 
        </g> 
        
        <rect x="136.602" y="35.901" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 189.24 229.5951)" style={{fill:"#FFBA00"}} width="69.446" height="31.348"></rect> 
        <rect x="305.955" y="444.776" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 45.6524 951.4037)" style={{fill:"#FF8E00"}} width="69.446" height="31.348"></rect> 
        <rect x="64.812" y="83.857" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 99.5365 240.2899)" style={{fill:"#FFBA00"}} width="69.443" height="31.347"></rect> 
        <rect x="377.752" y="396.795" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 412.4768 995.7908)" style={{fill:"#FF8E00"}} width="69.443" height="31.347"></rect> 
        <rect x="16.84" y="155.653" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 33.635 349.3437)" style={{fill:"#FFBA00"}} width="69.446" height="31.348"></rect> 
        <rect x="425.723" y="325.02" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 755.4605 831.662)" style={{fill:"#FF8E00"}} width="69.446" height="31.348"></rect> 
        <rect y="240.327" style={{fill:"#FFBA00"}} width="69.444" height="31.347"></rect> 
        <rect x="442.556" y="240.327" style={{fill:"#FF8E00"}} width="69.444" height="31.347"></rect> 
        <rect x="35.89" y="305.966" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 -243.4582 518.7056)" style={{fill:"#FFBA00"}} width="31.348" height="69.446"></rect> 
        <rect x="444.765" y="136.613" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 478.3505 662.2932)" style={{fill:"#FF8E00"}} width="31.348" height="69.446"></rect> 
        <rect x="83.857" y="377.74" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -121.7459 774.4951)" style={{fill:"#FFBA00"}} width="31.347" height="69.443"></rect> 
        <rect x="396.789" y="64.811" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 633.7369 461.5681)" style={{fill:"#FF8E00"}} width="31.347" height="69.443"></rect> 
        <rect x="155.643" y="425.726" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 153.3838 951.4084)" style={{fill:"#FFBA00"}} width="31.348" height="69.446"></rect> 
        <rect x="325.009" y="16.853" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 635.694 229.6021)" style={{fill:"#FF8E00"}} width="31.348" height="69.446"></rect> 
        <path style={{fill:"#FF6E7A"}} d="M267.685,107.077H256L235.102,256L256,404.923h11.685c61.124,0,110.676-49.551,110.676-110.676 v-76.494C378.361,156.628,328.81,107.077,267.685,107.077z"></path> 
        <path style={{fill:"#FFABB2"}} d="M244.315,107.077c-61.124,0-110.676,49.551-110.676,110.676v76.494 c0,61.124,49.551,110.676,110.676,110.676H256V107.077H244.315z"></path> 
        <path style={{fill:"#FF0F4D"}} d="M267.685,91.403H256l-10.449,15.672L256,130.741c7.441,6.275,12.169,15.657,12.169,25.976 c0,10.439-4.737,19.787-12.169,26.023l-10.449,19.601L256,222.03h106.687v18.296H256L245.551,256L256,271.673h106.687v18.294H256 l-10.449,15.82L256,329.258c7.432,6.236,12.169,15.586,12.169,26.023c0,10.319-4.727,19.702-12.169,25.976l-10.449,23.665 L256,420.595h11.685c69.67,0,126.349-56.68,126.349-126.349v-76.495C394.034,148.084,337.354,91.403,267.685,91.403z M289.965,190.686c6.055-9.904,9.549-21.534,9.549-33.968c0-10.9-2.707-21.465-7.764-30.877 c11.657,3.054,22.445,8.276,31.911,15.203h-13.524v31.347h41.002c3.145,5.764,5.705,11.89,7.612,18.294H289.965z M351.14,339.609 h-41.002v31.347h13.524c-9.466,6.927-20.253,12.148-31.911,15.202c5.056-9.41,7.764-19.975,7.764-30.876 c0-12.433-3.495-24.064-9.549-33.968h68.787C356.845,327.72,354.285,333.845,351.14,339.609z"></path> 
        
        <g> 
          <rect x="225.729" y="240.327" style={{fill:"#FF4F67"}} width="30.275" height="31.347"></rect> <path style={{fill:"#FF4F67"}} d="M237.98,389.033c-18.48-1.223-35.531-7.752-49.641-18.078h59.093v-31.347H160.86 c-3.145-5.764-5.705-11.89-7.613-18.294H234.2c8.291,0,15.895,2.991,21.799,7.944v-39.291h-17.044 c-18.73,0-33.968-15.238-33.968-33.968s15.238-33.968,33.968-33.968H256v-39.29c-5.904,4.954-13.507,7.944-21.799,7.944h-80.953 c1.907-6.404,4.467-12.53,7.612-18.294h86.572v-31.347h-59.094c14.11-10.326,31.161-16.855,49.642-18.078 c6.825,0.755,13.032,3.568,18.021,7.775V91.403h-11.685c-69.67,0-126.349,56.68-126.349,126.349v76.495 c0,69.669,56.68,126.349,126.349,126.349H256v-39.338C251.012,385.465,244.805,388.278,237.98,389.033z M149.313,271.673h13.706 v-31.347h-13.706v-18.294h33.878c-6.055,9.904-9.549,21.534-9.549,33.968s3.495,24.064,9.549,33.968h-33.878V271.673z"></path> </g> <g> <rect x="240.327" style={{fill:"#FFBA00"}} width="15.673" height="69.444"></rect> <rect x="240.327" y="442.556" style={{fill:"#FFBA00"}} width="15.673" height="69.444"></rect> </g> </g></svg>
  );
}

export default SvgComponent;
