import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";


export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="lightBg">
        <div className="container">
         
          <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Rug Pull? Hell No!!!</h1>
            <p className="font16">
          
          <br />
          We prioritize transparency and integrity in everything we do. Our defined tokenomics are designed with the utmost care to ensure a fair and secure environment for our community.

            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="lock"
                title="Locked Liquidity"
                subtitle="At launch, we're taking extra steps to ensure the safety and stability of our project. The liquidity will be LOCKED for the first 3 months. 🤝 After this initial period, if the market volume surpasses 50X the initial liquidity, we'll automatically extend the liquidity lock for an additional 1 year! 🌐💰 Your trust means everything to us!"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="audit"
                title="Audits"
                subtitle="All our smart contracts will be subjected to rigorous audits by reputable third-party firms, ensuring that they adhere to the highest security standards."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="fees"
                title="No Hidden Fees"
                subtitle="Our tokenomics are clear and straightforward (3% sell and buy tax (1% dev, 1% marketing, 1% in ETH reflections for staking purposes). There are no hidden fees or mechanisms that could lead to rug pulls."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="rocket"
                title="No Presale, No Early Investor Dumping"
                subtitle="In our commitment to fairness and community integrity, we've decided to forgo any presale for our token. This means no early investors dumping their tokens, ensuring an equitable start for everyone. But that's not all! We want you to know that, hand in hand with our community, the team is actively involved in creating liquidity. We believe in leading by example and actively contributing to the project's strength."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="secure"
                title="Secure and Transparent: Ownership Renounced"
                subtitle="We believe in transparency and a fair gaming environment for our players. Renouncing ownership ensures that the tokenomics and functionalities of the contract remain unchanged, providing you with confidence and security in your GAMEFI gaming experience."
              />
            </ServiceBoxWrapper>
           
          </ServiceBoxRow>
        </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
  padding-top: 4rem;
  font-size: 16px;

`;

const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;

