import React from "react";
import styled from "styled-components";
// Assets
import Battle from "../../assets/svg/Services/Battle";
import Arcade from "../../assets/svg/Services/Arcade";
import Competition from "../../assets/svg/Services/Competition";
import Brain from "../../assets/svg/Services/Brain";
import Reward from "../../assets/svg/Services/Reward";
import Locked from "../../assets/svg/Tokenomics/Locked";
import Audit from "../../assets/svg/Tokenomics/Audit";
import Fees from "../../assets/svg/Tokenomics/Fees";
import Secure from "../../assets/svg/Tokenomics/Secure";
import Rocket from "../../assets/svg/Tokenomics/Rocket";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "competition":
      getIcon = <Competition />;
      break;
    case "battle":
      getIcon = <Battle />;
      break;
    case "arcade":
      getIcon = <Arcade />;
      break;
    case "browser":
      getIcon = <Competition />;
      break;
    case "brain":
      getIcon = <Brain />;
      break;
    case "lock":
      getIcon = <Locked />
      break;
    case "audit":
      getIcon = <Audit />
      break;
    case "fees":
      getIcon = <Fees />
      break;
    case "secure":
      getIcon = <Secure />
      break;
    case "rocket":
      getIcon = <Rocket />
      break;
    default:
      getIcon = <Reward />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;