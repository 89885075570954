import * as React from "react";

function SvgComponent(props) {
  return (
    <svg 
    height="64px" 
    width="64px" 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 512 512" 
    xmlSpace="preserve" fill="#000000">
      
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier"> 
      <g> 
        <polygon style={{fill:"#464655"}} points="279.54,291.31 232.46,291.31 220.69,88.276 291.31,88.276 "></polygon> 
        <path style={{fill:"#464655"}} d="M370.759,397.241H141.241v-26.483c0-4.875,3.953-8.828,8.828-8.828h211.862 c4.875,0,8.828,3.953,8.828,8.828V397.241z"></path> 
      </g> 
      
      <path style={{fill:"#515262"}} d="M432.552,459.034H79.448v-52.966c0-4.875,3.953-8.828,8.828-8.828h335.448 c4.875,0,8.828,3.953,8.828,8.828V459.034z"></path> 
      <path style={{fill:"#464655"}} d="M79.448,459.034l15.644,46.93c1.202,3.605,4.575,6.036,8.374,6.036h34.119 c2.342,0,4.587-0.93,6.242-2.585l3.657-3.657c1.655-1.655,3.901-2.585,6.242-2.585h204.55c2.342,0,4.587,0.93,6.242,2.585 l3.657,3.657c1.655,1.655,3.901,2.585,6.242,2.585h34.119c3.799,0,7.174-2.431,8.374-6.036l15.642-46.93H79.448z"></path> 
      
      <g> 
          <path style={{fill:"#FF6464"}} d="M176.552,397.241H88.276l7.534-7.534c0.828-0.828,1.293-1.951,1.293-3.121V384 c0-2.438,1.976-4.414,4.414-4.414h61.793c2.438,0,4.414,1.976,4.414,4.414v2.585c0,1.171,0.465,2.293,1.293,3.121L176.552,397.241z "></path> <path style={{fill:"#FF6464"}} d="M423.724,397.241h-88.276l7.534-7.534c0.828-0.828,1.293-1.951,1.293-3.121V384 c0-2.438,1.976-4.414,4.414-4.414h61.793c2.438,0,4.414,1.976,4.414,4.414v2.585c0,1.171,0.465,2.293,1.293,3.121L423.724,397.241z "></path> </g> <g> <path style={{fill:"#515262"}} d="M317.793,353.103v-52.966c0-4.875-3.953-8.828-8.828-8.828H203.034 c-4.875,0-8.828,3.953-8.828,8.828v52.966c0,4.875-3.953,8.828-8.828,8.828h141.241 C321.746,361.931,317.793,357.978,317.793,353.103z"></path> <circle style={{fill:"#515262"}} cx="256" cy="52.966" r="52.966"></circle> </g> <path style={{fill:"#464655"}} d="M300.138,291.31h-88.276l6.388-12.776c1.495-2.99,4.552-4.879,7.895-4.879h59.709 c3.343,0,6.4,1.889,7.895,4.879L300.138,291.31z"></path> </g></svg>
  );
}

export default SvgComponent;
