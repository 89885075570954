import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderBackground from "../../assets/img/header/landing-back.png"

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
      <Img className="radius8" src={HeaderBackground} alt="office" style={{zIndex: 9, width: "80%"}} />
       
      </LeftSide>
      <RightSide>
      <div>
          <h1 className="extraBold font60">Learn  Play  Win!</h1>
          <HeaderP className="font13 semiBold">
            Unleashing the Power of GameFi on the Polygon Network!
          </HeaderP>
          <BtnWrapper style={{display: "none"}}>
            <FullButton title="Get Started" />
          </BtnWrapper>
        </div>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  
`;
const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 960px) {
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 30%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  font-size: 20px;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 60%;
    height: auto;
  }
`;
