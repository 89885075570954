import * as React from "react";

function SvgComponent(props) {
  return (
    <svg height="64px" 
    width="64px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M766.879869 519.521462V353.548627c0-142.992753-114.521574-258.151183-255.343689-258.151184-140.760683 0-255.34369 115.15843-255.34369 256.683956V518.181196c-37.024717 34.791623-56.687411 83.945286-56.687411 147.908426 0 162.080023 118.224967 262.750476 308.517127 262.750476 192.404435 0 311.904139-101.372838 311.904138-264.665142 0-63.259731-17.936423-110.308285-53.046475-144.653494zM628.561153 350.963316v161.155455H392.768554V349.846257c0.143344-31.615531 12.351129-60.723567 34.549986-82.634713 22.280769-22.055514 51.993921-34.184459 83.530613-34.184459 31.615531 0 61.233462 12.128946 83.51423 34.184459 22.133329 21.911146 34.341114 51.020206 34.341114 81.981474l-0.143344 1.770298z" fill="#27323A"></path>
        <path d="M720.740525 540.047291c-29.108036-14.299582-69.754236-16.724143-69.754235-16.724143s-268.685939 0.013311-313.083655 0.01331c-11.364103 0-23.382469 6.193482-35.046571 14.906747-1.562449 3.382917-3.846737 6.432048-6.831363 8.857632-31.981058 25.405667-47.492921 64.410578-47.492921 118.989809 0 192.91433 181.484698 213.723775 259.491449 213.723775 164.635641 0 262.877438-80.562369 262.877438-215.639465 0-53.366951-14.489001-91.475963-44.237989-116.371734-2.536164-2.154255-4.435471-4.869599-5.922153-7.755931z" fill="#9141ac"></path>
        <path d="M638.569632 526.418353c37.279665 0 72.388692 6.831363 87.262675 20.299551-4.915673-4.677108-7.978114-10.998576-7.978115-17.812533 0 0-0.065529-174.973811 0-176.823972 0-114.521574-92.564353-207.658278-206.318012-207.658278-113.756731 0-206.318012 93.136705-206.318012 209.125506v174.336954c0 7.46822-3.382917 14.492073-9.194491 19.215256 14.620059-13.740541 50.127378-20.682484 87.776665-20.682484H638.569632zM392.769578 349.846257c0.143344-31.615531 12.351129-60.723567 34.549986-82.634713 22.280769-22.055514 51.993921-34.184459 83.530613-34.184459 31.615531 0 61.233462 12.128946 83.51423 34.184459 22.133329 21.911146 34.341114 51.020206 34.341114 81.981474l-0.143344 1.771322V512.119795H392.769578V349.846257z" fill="#c061cb"></path>
        <path d="M606.268098 257.794871c-25.486554-25.199866-59.366919-39.067368-95.434303-39.067368s-69.950822 13.867503-95.434304 39.067368c-25.568464 25.280753-39.642792 58.825284-39.642792 94.493353l-0.126962 165.959524c0 4.501 3.670629 8.170605 8.170605 8.170605H637.545747c4.501 0 8.170605-3.670629 8.170605-8.170605l0.127985-165.972835c0-35.684451-14.056922-69.232054-39.576239-94.480042z m6.781192 235.939015H408.329563l0.111604-141.413922c0-26.889276 10.628954-52.202793 29.938406-71.286992 19.325836-19.103652 45.051978-29.621002 72.454222-29.621002 27.402243 0 53.128386 10.51735 72.454221 29.621002 19.260307 19.054506 29.872878 44.365975 29.872878 71.241941l-0.111604 141.458973zM435.827027 653.705729c0 21.639816 10.913594 40.473163 27.065385 54.389812v69.198266c0 18.064409 14.620059 32.363991 32.42952 32.363991h28.726127c17.87499 0 32.363991-14.492073 32.363991-32.363991v-69.136833c16.214248-13.979106 27.130913-32.811429 27.130913-54.451245 0-40.727087-33.131905-73.858992-73.858992-73.858992s-73.856944 33.131905-73.856944 73.858992z" fill="#27323A"></path>
        <path d="M495.321932 776.974355l0.254947-83.690339-7.66071-4.784616c-12.128946-7.66071-19.404675-20.685555-19.404674-34.792647 0-22.662678 18.511847-41.175548 41.172476-41.175548 22.725135 0 41.175548 18.511847 41.175549 41.175548 0 14.106068-7.278801 27.130913-19.470204 34.792647l-7.66071 4.784616v84.010815l-28.406674-0.320476z" fill="#FFFFFF"></path></g></svg>
   );
}

export default SvgComponent;
