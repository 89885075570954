import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import ClientLogo01 from "../../assets/svg/Footer/github-mark-white.svg"
import ClientLogo02 from "../../assets/svg/Footer/x-social-media-logo-icon.svg"
import ClientLogo03 from "../../assets/svg/Footer/debank_logo_freelogovectors.net_.png"

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <LogoImg />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                MindMaze
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">MindMaze</span> All Right Reserved
            </StyleP>
            <div style={{display: "flex"}}>
            <a className="flexCenter animate pointer" href="https://github.com/DareYourMind" target="_blank" >
              <LogoWrapper className="flexCenter">
                <ImgStyle src={ClientLogo01} alt="client logo" />
              </LogoWrapper>
            </a>
            <a className="flexCenter animate pointer" href="https://twitter.com/DareYourMind" target="_blank" >
              <LogoWrapper className="flexCenter">
                <ImgStyle src={ClientLogo02} alt="client logo" />
              </LogoWrapper>
            </a>
            <a className="flexCenter animate pointer" href="https://debank.com/profile/0x5e7b89de011c65da1e5ef1c2a7ad7065a96052d3" target="_blank" >
              <LogoWrapper className="flexCenter">
                <ImgStyle src={ClientLogo03} alt="client logo" />
              </LogoWrapper>
            </a>
            </div>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const LogoWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 30px;
  height: 30px;
  padding: 10%;
`;