import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Roadmap</h1>
            <p className="font15">
              Embark on a journey like no other as we unveil the roadmap for MindMaze, where the realms of gaming and decentralized finance converge to create a paradigm-shifting experience.
              <br />
              Our vision is to redefine the landscape of interactive entertainment, and we invite you to join us on this thrilling odyssey.
              In the coming quarters, we'll traverse uncharted territories, unveiling groundbreaking features, and introducing innovations that will reshape how we play and engage with digital experiences.
              <br />
              <br />
              This roadmap isn't just a plan; it's a testament to our commitment to revolutionize the gaming industry on the blockchain.
              Each milestone represents a chapter in our story — a story written by the community, for the community. 
              <br />
              Together, we'll explore the realms of single-player challenges, epic 2v2 battles, and a platform that empowers game creators to bring their visions to life. The integration of NFTs adds a touch of uniqueness, offering creators a gateway to a world where their creations are immortalized and rewarded.
              <br />
              <br />
              Are you ready to dive into a world where play-to-earn meets decentralized innovation? The MindMaze roadmap is more than a guide; it's an invitation to be part of a movement that's changing the way we game and interact with blockchain technology.
              Hold onto your controllers, crypto warriors — the adventure is about to begin!
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                title="Q1: Yellow Paper & Token Launch"
                text=""
                offers={[
                  { name: "Yellow Paper", cheked: false, description: "Dive into the technical details with the release of our Yellow Paper. Learn about the innovative architecture and mechanics powering the ecosystem." },
                  { name: "Token Launch", cheked: false, description: "Get ready to embark on a thrilling journey as we launch the $MIND token. Secure your tokens and be part of the genesis of decentralized gaming." },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                title="Q2: 1 Player and 2v2 Smart Contracts"
                text=""
                offers={[
                  { name: "Single-Player Contracts", cheked: false, description: "Experience the challenge solo! Engage in exciting games and puzzles as you compete for the top position. Earn rewards and showcase your gaming prowess." },
                  { name: "2v2 Contracts", cheked: false, description: "Unleash the competitive spirit! Introduce 2v2 contracts that enable players to challenge each other, placing bets on their skills. Engage in head-to-head battles where victory not only brings glory but also the spoils of the opponent's bet. A thrilling addition to the GAMEFI universe, turning every match into a high-stakes duel." },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                title="Q3: API for Game Creators & NFT Integration"
                text=""
                offers={[
                  { name: "API Launch", cheked: false, description: "Empower game creators! Introduce an intuitive API that allows developers to seamlessly integrate their own games into the GAMEFI ecosystem. Open the doors to a world of diverse gaming experiences." },
                  { name: "NFT Integration for Game Creators", cheked: false, description: "Innovate the gaming landscape. Game creators will mint a MIND NFT and attach it to their game. This NFT serves as a key to earning fees each time their game is played. Unlock a new era where creators are rewarded for the success of their games. This integration not only enriches the gaming experience but also creates a symbiotic relationship between creators and players. Join us as we redefine the future of gaming and blockchain collaboration!" },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




