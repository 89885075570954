import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>  
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Solo Races and Dynamic 2v2 Showdowns</h1>
            <p className="font16">
          Discover on-chain gaming with a mix of retro brain challenges and classic arcade fun. Compete for victory and win $MIND tokens. Start your diverse gaming adventure now!

            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="competition"
                title="Engage in Thrilling Competitions"
                subtitle="Dive into a world where gaming meets finance! Our GameFi platform hosts a variety of challenging and entertaining games designed to test your skills and strategic thinking."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="battle"
                title="Strategic Showdowns"
                subtitle="Engage in epic battles against other players. Plan your moves wisely, predict your opponent's strategy, and emerge as the master tacticians of the gaming arena."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="arcade"
                title="Arcade Game Extravaganza"
                subtitle="Experience the nostalgia of classic arcade games revamped for the blockchain era. Compete in fast-paced challenges that require quick reflexes and precision. Only the most skilled will triumph!."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="brain"
                title="Mind-Bending Challenges"
                subtitle="Race to the Top: Test Your Skills in Solo Showdowns. Challenge your intellect with a variety of mind-bending games that span different genres. Solve puzzles, strategize in dynamic battles, and outwit your opponents to emerge victorious in the gaming arena."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
              icon="printer" 
              title="Earn $MIND Rewards" 
              subtitle="Every victory comes with rewards! Compete for a chance to earn exclusive in-game assets and our native ERC-20 token. Rise through the ranks and claim your place among the MindMaze elite." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
       
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
